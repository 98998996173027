import ApiBase from 'apis';
import config from 'config';
import { Organization, OrganizationInviteDTO, Service, UpdateOrganizationDTO } from 'types/organization';
import { PaginationOptions } from 'types/api';
import { withPagination } from 'apis/utils';

const baseUrl = config.COMPANIES_API_URL;
const api = ApiBase();

export default {
  getBySlug: (slug: string) => `${baseUrl}/organizations/${slug}`,
  getPublic: (slug: string) => `${baseUrl}/organizations/profile/${slug}`,
  listAllUsersUrl: (organizationId: string) => `${baseUrl}/organizations/${organizationId}/users`,
  listAllServices: () => api.get<Service[]>(`${baseUrl}/labels/services`),
  listAllServicesUrl: `${baseUrl}/labels/services`,
  listAllTypes: () => api.get<Service[]>(`${baseUrl}/labels/organization-types`),
  listAllTypesUrl: `${baseUrl}/labels/organization-types`,
  update: (organizationId: string, dto: UpdateOrganizationDTO) =>
    api.put<Organization>(`${baseUrl}/organizations/${organizationId}`, dto),
  retractInvite: (organizationId: string, inviteCode: string) =>
    api.post<OrganizationInviteDTO>(`${baseUrl}/organizations/${organizationId}/invitations/${inviteCode}/retract`, {}),
  listInvitesUrl: (
    organizationId: string,
    paginationOptions?: PaginationOptions,
    extraParams?: { [key: string]: any },
  ) => withPagination(`${baseUrl}/organizations/${organizationId}/invitations`, paginationOptions, extraParams),
  inviteUsers: (organizationId: string, emails: string[]) =>
    api.post(`${baseUrl}/organizations/${organizationId}/invitations`, { userEmails: emails }),
  listUrl: () => `${baseUrl}/organizations`,
};
