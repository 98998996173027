import React, { useState } from 'react';
import Dialog, { Content, DialogActions, Title } from 'ui/views/dialogs/Dialog';
import ImpactGoals from 'domain/companies/ImpactGoals';
import { ImpactGoal } from 'types';
import Button from 'ui/elements/buttons/Button';
import useResourceLegacy from 'util/resource/useResourceLegacy';
import CompanyAPI from 'apis/CompanyAPI';
import Resource from 'util/resource/Resource';
import useNotify from 'hooks/useNotify';
import useLazyResource from 'util/resource/useLazyResource';
import { impactGoalsKey } from 'apis/CompanyAPI/companies/useImpactGoals';
import { invalidate } from 'hooks/useSWR';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  companyId: number;
  activeImpactGoals: ImpactGoal[];
}

export default function EditImpactGoalsDialog({ companyId, activeImpactGoals, isOpen, onClose }: Props) {
  const [selectedImpactGoals, setSelectedImpactGoals] = useState(activeImpactGoals);
  const [impactGoalListResource] = useResourceLegacy(CompanyAPI.impactGoals.listAll, []);
  const notify = useNotify();
  const [saveGoals, isSaving] = useLazyResource(
    (impactGoalIds: string[]) => CompanyAPI.impactGoals.save(companyId, impactGoalIds),
    {
      onSuccess: response => {
        setSelectedImpactGoals(() => response);
        notify('success', 'Impact goals updated');

        invalidate(impactGoalsKey(companyId));
        onClose();
      },
      onFailure: () => notify('error', 'Failed to update impact goals'),
    },
  );

  const onCloseDialog = () => {
    onClose();
    setSelectedImpactGoals(activeImpactGoals);
  };

  return (
    <Dialog onClose={onCloseDialog} open={isOpen} maxWidth="md">
      <Title onClose={onCloseDialog}>Impact goals</Title>
      <Content className="u-content-spacing-bottom">
        <Resource resource={impactGoalListResource}>
          {allImpactGoals => (
            <ImpactGoals
              activeImpactGoals={selectedImpactGoals}
              allImpactGoals={allImpactGoals.values}
              setActiveImpactGoals={setSelectedImpactGoals}
            />
          )}
        </Resource>
      </Content>
      <DialogActions className="u-section-spacing-top">
        <Button
          kind="primary"
          onClick={() => saveGoals(selectedImpactGoals.map(g => g.id))}
          isLoading={isSaving}
          disabled={isSaving}
        >
          Update
        </Button>
        <Button kind="tertiary" onClick={onCloseDialog}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
