import { communitiesApi } from 'apis/CompanyAPI/communities/communitiesApi';
import { useCommunities } from 'apis/CompanyAPI/communities/useCommunities';
import { communitiesByRole } from 'domain/companies/companyUtils';
import useCommunityRole from 'hooks/useCommunityRole';
import DeleteEvent from 'pages/Community/Events/DeleteEvent';
import EditEventDialog from 'pages/Community/Events/EditEventDialog';
import React from 'react';
import { Link } from 'react-router-dom';
import Attachments from 'ui/domain/Attachments/Attachments';
import CalendarDate from 'ui/domain/Event/CalendarDate';
import { EventDTO, getCalendarEvent } from 'ui/domain/Event/types';
import Logo from 'ui/domain/Logo';
import CalendarButton from 'ui/elements/buttons/CalendarButton';
import EditIcon from 'ui/elements/icons/EditIcon';
import EllipsisVertical from 'ui/elements/icons/EllipsisVertical';
import LocationIcon from 'ui/elements/icons/LocationIcon';
import TrashIcon from 'ui/elements/icons/TrashIcon';
import PopMenu from 'ui/modules/PopMenu';
import { MobileMargin } from 'ui/views/layouts/BaseLayout';
import { organizationUrls } from 'urls';
import { asTime, dateWithDayName } from 'util/dateUtils';
import { getOrUndefined } from 'util/resource';
import styles from './styles.scss';
import { MyCommunity } from 'types/company/community';
import HtmlContentWithMentions from 'domain/shared/HtmlContentWithMentions';

const getCommunityById = (communities: MyCommunity[], communityId?: number) => {
  if (!communityId) return undefined;
  const byRole = communitiesByRole(communities);
  return byRole.communitiesIManage.find(c => c.id === communityId)?.details;
};

export function ShowEvent({ event }: { event: EventDTO }) {
  const [showEditDialog, setShowEditDialog] = React.useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const eventsApi = communitiesApi.events;

  const { resource } = useCommunities();
  const communities = getOrUndefined(resource)?.values ?? [];
  const community = getCommunityById(communities, event.communityId);
  const communityRole = useCommunityRole(community?.slug ?? '').hasScope('Write', 'Community');
  const canEditEvent = community ? communityRole : false;

  const modifyEventItems = [
    {
      icon: <EditIcon fontSize="medium" />,
      text: <>Edit event</>,
      onClick: () => {
        setShowEditDialog(true);
      },
    },
    {
      icon: <TrashIcon fontSize="medium" />,
      text: <>Delete event</>,
      onClick: () => {
        setShowDeleteDialog(true);
      },
    },
  ];

  return (
    <>
      <MobileMargin>
        <div className={styles.container}>
          <div className={styles.left}>
            <div className={styles.header}>
              {event.title && <div className="text-h3 u-content-spacing-bottom">{event.title}</div>}
              {canEditEvent && <PopMenu icon={<EllipsisVertical />} iconColor="indigo" items={modifyEventItems} />}
            </div>
            <div className="u-flex">
              <CalendarDate date={event.startsAt} />
              <div className="u-content-spacing-left text-metadata u-flex u-flex--column u-flex-center">
                <div>
                  {dateWithDayName(event.startsAt)} {asTime(event.startsAt)}
                  {event.endsAt && ` - ${dateWithDayName(event.endsAt)} ${asTime(event.endsAt)}`}
                </div>
                <div className="u-flex-align-center">
                  Location:
                  {event.locationUrl ? (
                    <a
                      className="u-flex-align-center u-quarter-spacing-left"
                      href={event.locationUrl || '#'}
                      target={event.locationUrl ? '_blank' : '_self'}
                      rel="noreferrer"
                    >
                      <LocationIcon className="u-quarter-spacing-right" color="blue" fontSize="small" />
                      <span className={styles.eventLocation}>{event.location}</span>
                    </a>
                  ) : (
                    <span className="u-quarter-spacing-left">{event.location}</span>
                  )}
                </div>
              </div>
            </div>
            <HtmlContentWithMentions className={styles.eventContent}>{event.content}</HtmlContentWithMentions>
            {event.attachments.length > 0 && community && (
              <Attachments
                contentId={event.id}
                attachmentIds={event.attachments}
                listAttachments={() => eventsApi.listAttachments(community.id, event.id)}
                downloadAttachment={(_, documentId) => eventsApi.download(community.id, event.id, documentId)}
                isPreview={false}
                imgCount={0}
                onReadMore={() => {}}
              />
            )}
            <CalendarButton
              className="u-section-spacing-bottom"
              event={getCalendarEvent(event)}
              trackingKey={'event-add-to-calendar'}
            />
          </div>
          {event.organizations && event.organizations.length > 0 && (
            <div className={styles.right}>
              <span className="text-metadata">Hosted by</span>
              {event.organizations.map(org => (
                <Link
                  key={org.organization.id}
                  to={organizationUrls.view(org.organization.slug)}
                  className={`${styles.organization} u-flex u-flex-align-center u-half-spacing-y u-quarter-padding text-medium text-weight-medium`}
                >
                  <Logo className="u-half-spacing-right" type="company" url={org.organization.logoUrl} size={40} />
                  <span>{org.organization.name}</span>
                </Link>
              ))}
            </div>
          )}
        </div>
      </MobileMargin>

      {showEditDialog && canEditEvent && community && (
        <EditEventDialog community={community} event={event} onClose={() => setShowEditDialog(false)} />
      )}
      {showDeleteDialog && canEditEvent && community && (
        <DeleteEvent community={community} event={event} handleClose={() => setShowDeleteDialog(false)} />
      )}
    </>
  );
}
