import cx from 'classnames';
import { isSameDay } from 'date-fns';
import { stripContentAttachments } from 'domain/Updates/shared/utils';
import UserConversationDialog from 'domain/conversations/UserConversationDialog';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import useDialogHandler from 'hooks/useDialogHandler';
import React from 'react';
import { Link } from 'react-router-dom';
import { TinyOrganizationDTO } from 'types/organization';
import HtmlContent from 'ui/elements/HtmlContent';
import Avatar from 'ui/elements/avatars/Avatar';
import AvatarGroup from 'ui/elements/avatars/AvatarGroup';
import LinkAsButton from 'ui/elements/buttons/LinkAsButton';
import ChatAddIcon from 'ui/elements/icons/ChatAddIcon';
import CheckMarkCircleIcon from 'ui/elements/icons/CheckmarkCircleIcon';
import LinkIcon from 'ui/elements/icons/LinkIcon';
import LocationIcon from 'ui/elements/icons/LocationIcon';
import ShareIcon from 'ui/elements/icons/ShareIcon';
import PopMenu, { PopMenuItem } from 'ui/modules/PopMenu';
import Card from 'ui/views/cards/Card';
import { eventUrls, organizationUrls } from 'urls';
import { notEmpty } from 'util/arrayUtils';
import { asTime, dateWithDayName } from 'util/dateUtils';
import CalendarDate from './CalendarDate';
import styles from './styles.scss';
import { EventDTO } from './types';

interface EventPreviewProps {
  event: EventDTO;
  trackingKey?: string;
}

interface EventHeaderProps {
  event: EventDTO;
}

function EventHeader({ event }: EventHeaderProps) {
  return (
    <div className="u-flex u-flex--align-items-start">
      <CalendarDate date={event.startsAt} />
      <div className={styles.eventHeading}>
        <div>
          {dateWithDayName(event.startsAt)}
          <br />{' '}
          {event.endsAt && isSameDay(new Date(event.startsAt), new Date(event.endsAt))
            ? `${asTime(event.startsAt)} - ${asTime(event.endsAt)}`
            : `at ${asTime(event.startsAt)}`}
        </div>
        <div>
          Location:{' '}
          {event.locationUrl ? (
            <a href={event.locationUrl || '#'} target={event.locationUrl ? '_blank' : '_self'} rel="noreferrer">
              <LocationIcon color="blue" fontSize="small" />
              <span className={styles.eventLocation}>{event.location}</span>
            </a>
          ) : (
            <span>{event.location}</span>
          )}
        </div>
      </div>
    </div>
  );
}

function ShareButton({ event }: { event: EventDTO }) {
  const shareEventDialogHandler = useDialogHandler();

  const { copy, showCopiedSuccess } = useCopyToClipboard(
    `${location.origin}${eventUrls.view(event.id)}?utm_source=direct&utm_medium=copy-link&utm_campaign=event`,
  );

  const menuItems: (PopMenuItem | undefined)[] = [
    {
      text: <span>Copy link to event</span>,
      onClick: copy,
      icon: showCopiedSuccess ? <CheckMarkCircleIcon strokeWidth={2} fontSize="small" color="green" /> : <LinkIcon />,
    },
    {
      text: <span>Share in chat</span>,
      onClick: shareEventDialogHandler.open,
      icon: <ChatAddIcon fontSize="small" />,
    },
    navigator.share
      ? {
          text: <span>More options</span>,
          onClick: () =>
            navigator.share({
              title: `Join me at ${event.title}!`,
              url: `${location.origin}${eventUrls.view(event.id)}?utm_source=direct&utm_medium=native-share&utm_campaign=event`,
            }),
          icon: <ShareIcon />,
        }
      : undefined,
  ];

  return (
    <>
      <PopMenu icon={<ShareIcon />} items={menuItems.filter(notEmpty)} />
      {shareEventDialogHandler.isOpen && (
        <UserConversationDialog
          sharedContent={{ type: 'event', eventId: event.id }}
          conversation={{ type: 'new' }}
          closeConversation={shareEventDialogHandler.close}
        />
      )}
    </>
  );
}

function OrganizationList({ organizations, eventId }: { organizations: TinyOrganizationDTO[]; eventId: string }) {
  const LOGO_SIZE = 32;
  // Maximum number of icons and org names displayed. If the number of orgs exceeds this, then only DISPLAY_LIMIT - 1 are displayed,
  // and instead the last displayed icon and org name will be the +n icon and "n others" text
  const DISPLAY_LIMIT = 3;

  const OrganizationNames = () => {
    return (
      <>
        {organizations
          .slice(0, organizations.length > DISPLAY_LIMIT ? DISPLAY_LIMIT - 1 : DISPLAY_LIMIT)
          .map((org, idx) => (
            <React.Fragment key={`org-name-${org.slug}`}>
              <Link to={organizationUrls.view(org.slug)}>
                <span>{org.name}</span>
              </Link>
              {idx < Math.min(organizations.length - 1, DISPLAY_LIMIT - 1) && ', '}
            </React.Fragment>
          ))}
        {organizations.length > DISPLAY_LIMIT ? (
          <Link to={{ pathname: eventUrls.view(eventId), state: { fromPreview: true } }}>
            and {organizations.length - DISPLAY_LIMIT + 1} others
          </Link>
        ) : undefined}
      </>
    );
  };
  return (
    <div className="u-flex u-flex-align-center text-metadata">
      <div className="u-quarter-spacing-right">By</div>
      <AvatarGroup size={LOGO_SIZE} max={DISPLAY_LIMIT}>
        {organizations.map(org => (
          <Avatar key={organizationUrls.view(org.slug)} imageUrl={org.logoUrl} borderColor="grey" />
        ))}
      </AvatarGroup>
      <div>
        <OrganizationNames />
      </div>
    </div>
  );
}

export default function EventPreview({ event, trackingKey }: EventPreviewProps) {
  const strippedContent = stripContentAttachments(event.content);

  return (
    <Card className={styles.eventCard} elevation={1}>
      <EventHeader event={event} />
      <div className={styles.eventPreviewContainer}>
        <div className={styles.previewDescription}>{event.title}</div>
        <div className={styles.previewContent}>
          <HtmlContent>{strippedContent}</HtmlContent>
        </div>
        {event.organizations && event.organizations?.length > 0 && (
          <OrganizationList organizations={event.organizations.map(o => o.organization)} eventId={event.id} />
        )}
      </div>
      <div className="u-half-spacing-top u-flex u-flex-space-between">
        <LinkAsButton
          className={cx('u-ellipsis', trackingKey ? `data-track-${trackingKey}` : undefined)}
          kind="tertiary"
          url={{ pathname: eventUrls.view(event.id), state: { fromPreview: true } }}
        >
          View details
        </LinkAsButton>
        <ShareButton event={event} />
      </div>
    </Card>
  );
}
