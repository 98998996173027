import { FormControl, FormControlLabel, RadioGroup } from '@mui/material';
import React, { useState } from 'react';
import Button from 'ui/elements/buttons/Button';
import Chip from 'ui/elements/Chip';
import Radio from 'ui/elements/form/choice/Radio';
import Dialog, { Title, Content, DialogActions } from 'ui/views/dialogs/Dialog';
import { DialogHandlerProps } from 'hooks/useDialogHandler';
import { useUpdateCompany } from 'apis/CompanyAPI/companies/useUpdateCompany';
import useNotify from 'hooks/useNotify';

interface Props {
  dialogHandler: DialogHandlerProps;
  companyId: number;
  companySlug: string;
  behaviourOnEditPitch?: 'closeDialog' | 'redirectToPitch';
  hasPublicPitch: boolean;
  onSave?: () => void;
}

function PitchVisibility(props: Props) {
  const notify = useNotify();
  const [updateCompany, isUpdatingCompany] = useUpdateCompany(props.companyId, props.companySlug, {
    onSuccess: () => {
      props.onSave && props.onSave();
      props.dialogHandler.close();
      notify('success', 'Updated company visibility');
    },
    onFailure: () => {
      notify('error', 'Failed to update company visibility');
    },
  });

  const visibilityOptions = [
    {
      id: 'restricted',
      title: 'Company connections & communities only',
      chip: null,
      description:
        'Only invited connections of your company and communities it is part of can see your company profile',
      hasPublicPitch: false,
    },
    {
      id: 'public',
      title: 'Public',
      chip: 'Recommended',
      description: 'A limited view of your company profile is accessible for all users on CrowdWorks.',
      hasPublicPitch: true,
    },
  ];

  const [pitchVisibility, setPitchVisibility] = useState<string>(
    visibilityOptions.find(o => o.hasPublicPitch === props.hasPublicPitch)?.id ?? '',
  );

  function save() {
    const selectedOption = visibilityOptions.find(o => o.id === pitchVisibility);
    if (!selectedOption) {
      return;
    }

    if (selectedOption.hasPublicPitch === props.hasPublicPitch) {
      props.dialogHandler.close();
      return;
    }

    updateCompany({ company: { hasPublicPitch: selectedOption.hasPublicPitch } });
  }

  return (
    <>
      <Title onClose={props.dialogHandler.close}>Company visibility</Title>
      <Content>
        <FormControl>
          <RadioGroup
            name="visibility"
            value={pitchVisibility}
            onChange={event => {
              setPitchVisibility(event.currentTarget.value);
            }}
          >
            {visibilityOptions.map(option => (
              <FormControlLabel
                className="u-half-spacing-bottom"
                style={{ alignItems: 'flex-start' }}
                key={option.id}
                control={<Radio />}
                value={option.id}
                label={
                  <div className="u-half-spacing-top">
                    <div className="u-flex u-flex--align-items-center" style={{ gap: '0.4rem' }}>
                      {option.title}
                      {option.chip && <Chip color="green-medium" label={option.chip} />}
                    </div>
                    <div style={{ fontWeight: 'normal' }}>{option.description}</div>
                  </div>
                }
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Content>
      <DialogActions>
        <Button kind="primary" onClick={save} isLoading={isUpdatingCompany}>
          Save
        </Button>
        <Button kind="tertiary" onClick={props.dialogHandler.close}>
          Cancel
        </Button>
      </DialogActions>
    </>
  );
}

export default function PitchVisibilityDialog(props: Props) {
  return (
    <Dialog open={props.dialogHandler.isOpen} onClose={props.dialogHandler.close}>
      <PitchVisibility {...props} />
    </Dialog>
  );
}
