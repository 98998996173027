import styled from '@emotion/styled';
import { useMediaQuery } from '@mui/material';
import Banner from 'domain/shared/Banner';
import React, { useState } from 'react';
import { CompanyProfile } from 'types/company';
import CompanyLogo from 'ui/elements/CompanyLogo';
import EditAction from 'ui/elements/actions/EditAction';
import CtaButton from 'ui/elements/buttons/CtaButton';
import LinkButton from 'ui/elements/buttons/LinkButton';
import ChevronLeftIcon from 'ui/elements/icons/ChevronLeftIcon';
import IconButton from 'ui/elements/icons/IconButton';
import { bluePlanetTheme } from 'ui/theme';
import { contentSpacing, halfSpacing } from 'ui/theme/themeConstants';
import Card from 'ui/views/cards/Card';
import { scrollToTop } from 'util/scroll';
import { pluralize } from 'util/stringUtils';
import LastUpdated from '../LastUpdated';
import BrandingDialog from './BrandingDialog';
import WelcomeMessage from './WelcomeMessage';

type Props = {
  companyProfile: CompanyProfile;
  onEdit: () => void;
  onBack?: () => void;
  canEditCompany: boolean;
  action?: JSX.Element;
};

export const LogoHeader = styled.h1`
  display: flex;
  align-items: center;
  margin: 0;
  width: 100%;
  gap: ${contentSpacing};
  ${bluePlanetTheme.breakpoints.down('sm')} {
    gap: ${halfSpacing};
  }
`;

export const LogoBorder = styled.div`
  background: ${bluePlanetTheme.bluePlanetPalette.white};
  border-radius: 50%;
  border: 1px solid ${bluePlanetTheme.bluePlanetPalette.disabled};
`;

const NameAndUpdatedAt = styled.div`
  overflow: hidden;
  flex: 1;
`;

export default function CompanyHeader({ companyProfile: company, onEdit, onBack, canEditCompany, action }: Props) {
  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    scrollToTop();
  };

  return (
    <>
      {canEditCompany && <WelcomeMessage company={company} />}
      {!isMobile && (
        <Card padding="none" data-intercom-target="company-overview-header">
          {canEditCompany && !company.imageURL ? (
            <CtaButton
              style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
              height={245}
              onClick={openDialog}
            >
              Add a banner image
            </CtaButton>
          ) : (
            <Banner
              style={{
                borderRadius: `${bluePlanetTheme.shape.borderRadius}px ${bluePlanetTheme.shape.borderRadius}px 0 0`,
              }}
              imageURL={company.imageURL}
            />
          )}
        </Card>
      )}
      <Card className="u-half-spacing-bottom">
        <div className="u-flex-space-between u-flex-align-center">
          <LogoHeader className="text-h4">
            {isMobile && onBack && (
              <IconButton
                style={{ marginLeft: `-${contentSpacing}`, marginRight: `-${halfSpacing}` }}
                onClick={onBack}
                color="indigo"
              >
                <ChevronLeftIcon color="indigo" />
              </IconButton>
            )}
            {canEditCompany && !company.logoURL ? (
              <CtaButton
                className={onBack && isMobile ? undefined : 'u-content-spacing-left'}
                shape="circle"
                height={60}
                onClick={openDialog}
              >
                Add logo
              </CtaButton>
            ) : (
              <LogoBorder>
                <CompanyLogo size={isMobile ? 40 : 60} company={company} />
              </LogoBorder>
            )}
            <NameAndUpdatedAt>
              <div className="u-ellipsis">{company.name}</div>
              <LastUpdated compact={isMobile} companyId={company.id} lastUpdated={company.lastUpdated} />
            </NameAndUpdatedAt>
            <div className="u-flex-align-center u-flex--gap-1 text-small">
              {!isMobile && (
                <>
                  {company.connectionCount} {pluralize(company.connectionCount, 'Connection', 'Connections')}
                </>
              )}
              {canEditCompany && (
                <div style={{ alignSelf: 'flex-start' }}>
                  <LinkButton onClick={() => setIsDialogOpen(true)} aria-label="Edit header section">
                    <EditAction tooltip="Edit section" />
                  </LinkButton>
                </div>
              )}
              {action}
            </div>
          </LogoHeader>
        </div>
        {isMobile && (
          <div className="u-flex u-content-spacing-top u-flex-space-between u-flex-align-center">
            {company.connectionCount} {pluralize(company.connectionCount, 'Connection', 'Connections')}
          </div>
        )}
      </Card>
      {isDialogOpen && <BrandingDialog company={company} onEdit={onEdit} onClose={closeDialog} />}
    </>
  );
}
