import React from 'react';
import useResource from 'util/resource/useResource';
import OrganizationAPI from 'apis/OrganizationAPI';
import Resource from 'util/resource/Resource';
import { CompactOrganization } from 'types/organization';
import { useRouteMatch } from 'react-router-dom';
import ScrollableBody from 'ui/views/layouts/BaseLayout/ScrollableBody';
import Header from 'ui/views/layouts/Public/Header';
import PrivatePage from 'domain/shared/PrivatePage';
import Footer from 'ui/views/layouts/Public/Footer';
import { useLoginWithRedirect } from 'auth/useLoginWithRedirect';
import { PublicOrganization } from 'pages/Organization/Public/PublicOrganization';

export const PublicOrganizationPage = () => {
  const routeMatch = useRouteMatch<{ slug: string }>();

  const { resource } = useResource<CompactOrganization>(OrganizationAPI.getPublic(routeMatch.params.slug));
  const { loginWithRedirect } = useLoginWithRedirect();

  return (
    <main>
      <ScrollableBody accountForMobileHeader={false} hideFooter>
        <Header color="white" />
        <Resource
          resource={resource}
          renderLoading="Bar"
          renderError={() => (
            <PrivatePage
              heading="Private profile"
              body={
                <p className="text-body text-large u-section-spacing-top">
                  This organization is private. If you are connected to the organization you may view it by{' '}
                  <button className="text-link" onClick={() => loginWithRedirect('login')}>
                    logging in
                  </button>
                </p>
              }
            />
          )}
        >
          {org => {
            return <PublicOrganization organization={org} />;
          }}
        </Resource>
        <Footer />
      </ScrollableBody>
    </main>
  );
};
