import { FormHelperText, useMediaQuery, useTheme } from '@mui/material';
import OrganizationAPI from 'apis/OrganizationAPI';
import { useUpdateOrganization } from 'apis/OrganizationAPI/organizations/useUpdateOrganization';
import Picker from 'domain/labels/Picker';
import LocationInput from 'domain/shared/Location/LocationInput';
import { FormikProps } from 'formik';
import useNotify from 'hooks/useNotify';
import { SUCCESS_WIDTH } from 'pages/CompanySettings/utils';
import { IndustryResource } from 'pages/Organization/shared/EditableIndustries';
import React, { useState } from 'react';
import { Industry } from 'types';
import { Organization, OrganizationType } from 'types/organization';
import Button from 'ui/elements/buttons/Button';
import ButtonList from 'ui/elements/buttons/ButtonList';
import FormRow from 'ui/elements/form/FormRow';
import FormikTextField from 'ui/elements/form/formik/FormikTextField';
import EyeIcon from 'ui/elements/icons/EyeIcon';
import i18n from 'util/i18n';
import { formatShortLocation } from 'util/locationUtils';
import Resource from 'util/resource/Resource';
import useResource from 'util/resource/useResource';

interface GeneralInformationSettingsProps {
  organization: Organization;
  onShowPreview: () => void;
  formik: FormikProps<any>;
}

export function GeneralInformationSettings(props: GeneralInformationSettingsProps) {
  const notify = useNotify();

  const [success, setSuccess] = useState(false);
  const [updateOrganization, isUpdatingOrganization] = useUpdateOrganization(props.organization, {
    onSuccess: () => {
      setSuccess(true);
      notify('success', 'Settings saved');
    },
  });

  const industryResource = IndustryResource();
  const { resource: organizationTypesResource } = useResource<OrganizationType[]>(OrganizationAPI.listAllTypesUrl);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <form className="u-flex u-flex-grow u-flex--column">
      <FormRow className={isMobile ? 'u-full-width' : 'u-flex u-flex--gap-1'}>
        <FormikTextField name="name" formikProps={props.formik} label="name" type="string" />
        <FormikTextField name="established" formikProps={props.formik} label="established" type="number" />
      </FormRow>
      <FormRow className={isMobile ? 'u-full-width' : 'u-flex u-flex--gap-1'}>
        <div className="u-fullWidth">
          <LocationInput
            name="location"
            label="Location"
            searchArea='places'
            placeholder={i18n('en').placeholders.city}
            onChange={v => {
              props.formik.setFieldValue('location', v ? formatShortLocation(v) : undefined);
            }}
            value={
              props.formik.values.location
                ? { description: props.formik.values.location, longitude: 0, latitude: 0, place: '' }
                : undefined
            }
          />
          <FormHelperText> </FormHelperText>
        </div>
        <FormikTextField
          name="website"
          formikProps={props.formik}
          error={!!props.formik.errors.website}
          label="website"
          type="string"
        />
      </FormRow>
      <FormRow style={{ margin: '0' }}>
        <Resource resource={industryResource}>
          {industryResource => (
            <Picker
              label={'Industries'}
              name={'Industries'}
              setItems={industries => {
                const mappedIndustries = industries
                  .map(i => industryResource.find(ir => ir.name === i.name))
                  .filter(i => i !== undefined);

                props.formik.setFieldValue('industries', mappedIndustries);
              }}
              selectedItems={props.formik.values.industries}
              optionLabel={industries => industries.name}
              optionsValues={industryResource}
              placeholder={'Select industries'}
            />
          )}
        </Resource>
        <FormHelperText> </FormHelperText>
      </FormRow>
      <FormRow style={{ margin: '0' }}>
        <Resource resource={organizationTypesResource}>
          {typesResource => (
            <Picker
              label={'Type'}
              name={'Type'}
              setItems={types => {
                const mappedTypes = types
                  .map(type => typesResource.find(i => i.name === type.name))
                  .filter(i => i !== undefined);

                props.formik.setFieldValue('types', mappedTypes);
              }}
              selectedItems={props.formik.values.types}
              optionLabel={type => type.name}
              optionsValues={typesResource}
              placeholder={'Select types'}
            />
          )}
        </Resource>
        <FormHelperText> </FormHelperText>
      </FormRow>
      <FormRow>
        <FormikTextField
          formikProps={props.formik}
          fullWidth
          name="description"
          placeholder={'Description'}
          multiline
          minRows={5}
          maxRows={12}
          label="Description"
          suffixPosition="start-bottom"
        />
      </FormRow>
      <ButtonList align="left" className="u-content-spacing-top">
        <Button
          onClick={async () => {
            props.formik.validateForm().then(validationErrors => {
              if ((validationErrors.website?.length ?? 0) !== 0) {
                notify('error', 'Website should be a valid url, e.g. https://example.com');
              } else {
                updateOrganization({
                  name: props.formik.values.name,
                  description: props.formik.values.description,
                  website: props.formik.values.website,
                  location: props.formik.values.location,
                  established: props.formik.values.established,
                  industries: props.formik.values.industries.some((i: Industry) => i.id === -1)
                    ? undefined
                    : props.formik.values.industries.map((i: Industry) => i.id),
                  types: props.formik.values.types.some((t: OrganizationType) => t.id === t.name)
                    ? undefined
                    : props.formik.values.types.map((t: OrganizationType) => t.id),
                });
              }
            });
          }}
          kind="primary"
          isLoading={isUpdatingOrganization}
          isSuccess={success}
          setSuccess={(isSuccess: boolean) => setSuccess(isSuccess)}
          style={{ width: `${SUCCESS_WIDTH}px` }}
          disabled={isUpdatingOrganization || success}
        >
          Update
        </Button>
        <Button kind="tertiary" color="grey" onClick={props.onShowPreview}>
          <EyeIcon className="u-quarter-spacing-right" /> Preview profile
        </Button>
      </ButtonList>
    </form>
  );
}
