import { useAuth0 } from '@auth0/auth0-react';
import AuthenticatedRoutes, { LoadingScreen } from 'auth/AuthenticatedRoutes';
import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { OrganizationContainer } from 'pages/OrganizationContainer';
import OrganizationPage from 'pages/Organization';
import { Organization } from 'types/organization';
import NotFound from 'pages/common/error/NotFoundPage';
import { RouteProps } from 'react-router';
import { OrganizationSettingsPage } from 'pages/Organization/Settings';
import { ErrorBoundary } from '@sentry/react';
import { PublicOrganizationPage } from 'pages/Organization/Public';
import { externalUrlFetcher } from 'apis/utils';
import { SWRConfig } from 'swr';

interface AdminOrganizationRouteProps extends RouteProps {
  organization: Organization;
}

const AdminOrganizationRoute = (props: AdminOrganizationRouteProps) => {
  if (!props.organization.isAdmin) {
    return <NotFound />;
  }

  return <Route {...props} />;
};

type OrganizationRoutesProps = RouteComponentProps<{ slug: string }>;

export const OrganizationRoutes = ({ match }: OrganizationRoutesProps) => {
  const { isAuthenticated, isLoading } = useAuth0();
  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    return (
      <Switch>
        <SWRConfig value={{ fetcher: externalUrlFetcher }}>
          <Route path={`/organizations/:slug`} component={PublicOrganizationPage} />
        </SWRConfig>
      </Switch>
    );
  }

  return (
    <AuthenticatedRoutes>
      <OrganizationContainer slug={match.params.slug}>
        {organization => (
          <ErrorBoundary>
            <Switch>
              <AdminOrganizationRoute
                organization={organization}
                path={`${match.path}/settings`}
                render={() => <OrganizationSettingsPage organization={organization} />}
              />
              <Route path={match.path} render={() => <OrganizationPage organization={organization} />} />
            </Switch>
          </ErrorBoundary>
        )}
      </OrganizationContainer>
    </AuthenticatedRoutes>
  );
};
