import React from 'react';

import { DocumentList } from 'types/documents';
import Dialog, { Title } from 'ui/views/dialogs/Dialog';
import ManagePitchDocuments from './ManagePitchDocuments';
import { ICompany, VideoListDTO } from 'types/company';

interface Props {
  company: ICompany;
  onClose: () => void;
  documents: DocumentList;
  videos: VideoListDTO;
  onSaveVideos: (videos: VideoListDTO) => void;
  onDocumentUploaded: () => void;
  onDocumentDeleted: (documentId: string) => void;
  shouldAutofocusVideos?: boolean;
}

export default function ManagePitchDocumentsDialog({
  company,
  onClose,
  documents,
  videos,
  onSaveVideos,
  onDocumentDeleted,
  onDocumentUploaded,
  shouldAutofocusVideos,
}: Props) {
  return (
    <Dialog open onClose={onClose} maxWidth="md">
      <Title onClose={onClose}>Manage pitch documents</Title>
      <ManagePitchDocuments
        company={company}
        onClose={onClose}
        onDocumentDeleted={onDocumentDeleted}
        onDocumentUploaded={onDocumentUploaded}
        documents={documents}
        videos={videos}
        onSaveVideos={onSaveVideos}
        shouldAutofocusVideos={shouldAutofocusVideos}
      />
    </Dialog>
  );
}
