import React, { useState } from 'react';
import cx from 'classnames';
import { Modal } from '@mui/material';
import { DialogHandlerProps } from 'hooks/useDialogHandler';
import CompanyList from './CompanyList';
import styles from './styles.scss';
import fuzzysearch from 'fuzzysearch';
import SearchInput from 'ui/elements/form/SearchInput';
import { companiesCommunitiesByRole } from 'domain/companies/companyUtils';
import { useCompanies } from 'apis/CompanyAPI/companies/useCompanies';
import { useCommunities } from 'apis/CompanyAPI/communities/useCommunities';
import { getOrElse, getOrUndefined } from 'util/resource';
import { emptyPaginatedResult } from 'util/paginationUtils';
import { useSentFollowerRequests } from 'apis/CompanyAPI/users/useSentFollowerRequests';
import { useOrganizations } from 'apis/OrganizationAPI/organizations/useOrganizations';

interface Props {
  dialogHandler: DialogHandlerProps;
}

interface CustomDrawerProps {
  dialogHandler: DialogHandlerProps;
  children: React.ReactNode;
}

const CustomDrawer = ({ dialogHandler, children }: CustomDrawerProps) => {
  return (
    <Modal open={dialogHandler.isOpen} onClose={dialogHandler.close} BackdropProps={{ invisible: true }}>
      <div
        onClick={e => e.stopPropagation()}
        className={cx(styles.drawer, { [styles.drawerOpen]: dialogHandler.isOpen })}
      >
        {children}
      </div>
    </Modal>
  );
};

function CompanySelector({ dialogHandler }: Props) {
  const [search, setSearch] = useState('');

  const companiesResource = useCompanies();
  const communitiesResource = useCommunities();
  const organizationsResource = useOrganizations();
  const companies = getOrElse(companiesResource.resource, emptyPaginatedResult()).values;
  const communities = getOrUndefined(communitiesResource.resource)?.values ?? [];
  const organizations = getOrUndefined(organizationsResource.resource) ?? [];
  const { resource: pendingFollowerRequests } = useSentFollowerRequests();

  const filteredCommunities = !search
    ? communities
    : communities.filter(c => fuzzysearch(search.toLowerCase(), c.details.name.toLowerCase()));
  const filteredCompanies = !search
    ? companies
    : companies.filter(c => fuzzysearch(search.toLowerCase(), c.name.toLowerCase()));

  const byRole = companiesCommunitiesByRole(filteredCompanies, filteredCommunities);

  const showSearch = communities.length + companies.length > 9;
  const hasCompaniesOrCommunities = communities.length + companies.length > 0;
  const hasFilteredResults = filteredCommunities.length + filteredCompanies.length > 0;
  if (!hasCompaniesOrCommunities) {
    return (
      <CustomDrawer dialogHandler={dialogHandler}>
        <p className="u-section-spacing">When you get invited to a community or a company, it will be listed here.</p>
      </CustomDrawer>
    );
  }

  return (
    <CustomDrawer dialogHandler={dialogHandler}>
      {showSearch && (
        <div className="u-section-spacing-top u-section-spacing-x">
          <SearchInput color="lightBlue" onChange={value => setSearch(value)} value={search} />
        </div>
      )}
      {!hasFilteredResults && <p className="u-section-spacing">No companies or communities matched your search.</p>}
      <CompanyList
        onClick={dialogHandler.close}
        context="community"
        companies={byRole.communitiesIManage.map(c => c.details)}
        title="Communities you manage"
      />
      <CompanyList
        onClick={dialogHandler.close}
        context="company"
        companies={byRole.companiesIManage}
        title="Companies you manage"
      />
      <CompanyList
        companies={organizations.filter(org => org.isAdmin)}
        context="organization"
        title="Organizations you manage"
        onClick={dialogHandler.close}
      />
      <CompanyList
        onClick={dialogHandler.close}
        context="company"
        companies={byRole.myInvestments}
        title="Your portfolio"
      />
      <CompanyList
        onClick={dialogHandler.close}
        context="community"
        companies={byRole.myCommunities.map(c => c.details)}
        title="Communities"
      />
      <CompanyList
        companies={organizations.filter(org => !org.isAdmin)}
        context="organization"
        title="Organizations"
        onClick={dialogHandler.close}
      />
      <CompanyList
        onClick={dialogHandler.close}
        context="company"
        companies={byRole.myBoardPositions}
        title="Board positions"
      />
      <CompanyList
        onClick={dialogHandler.close}
        context="company"
        companies={byRole.companiesIFollow}
        title="Companies you follow"
      />
      <CompanyList
        onClick={dialogHandler.close}
        context="company"
        companies={(getOrUndefined(pendingFollowerRequests)?.values ?? []).map(r => r.company)}
        title="Pending follower requests"
      />
    </CustomDrawer>
  );
}

export default CompanySelector;
