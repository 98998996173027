import React, { Suspense } from 'react';
import { useSWRResource } from 'util/resource/useSWRResource';
import useSWR from 'hooks/useSWR';
import Resource from 'util/resource/Resource';
import { useCommunities } from 'apis/CompanyAPI/communities/useCommunities';
import { useCompanies } from 'apis/CompanyAPI/companies/useCompanies';
import { OnboardingWizardStatus, onboardingApiUrls } from 'apis/OnboardingAPI';
import { useSelfUserProfile } from 'apis/CompanyAPI/users/useSelfUserProfile';
import OnboardingWrapper from './Onboarding';
import LoadingBar from 'ui/elements/Loading/LoadingBar';
import ErrorCard from 'ui/views/errors/ErrorCard';

export const onboardinWizardStatusKey = onboardingApiUrls.getStatus;

const useOnboardingWizardStatus = () => {
  const swrResult = useSWR<OnboardingWizardStatus>(onboardingApiUrls.getStatus);
  return useSWRResource(swrResult);
};

export default function InitialLoadWrapper({ children }: { children: React.ReactNode }) {
  const { resource: onboardingStatus } = useOnboardingWizardStatus();
  // eager load the following resources. They are used after the onboarding is completed.
  useSelfUserProfile();
  useCompanies();
  useCommunities();

  return (
    <Suspense fallback={<LoadingBar isComplete={false} />}>
      <Resource
        resource={onboardingStatus}
        renderLoading="Bar"
        renderError={() => (
          <>
            <div className="u-section-padding-y">
              <div className="u-section-padding-top">
                <ErrorCard />
              </div>
            </div>
            {/* Since the layout is currently flex, we need this in place of the right hand sidebar that doesn't load */}
            <div />
          </>
        )}
      >
        {onboardingStatus =>
          onboardingStatus.status === 'InProgress' ? (
            <OnboardingWrapper onboardingStatus={onboardingStatus}>{children}</OnboardingWrapper>
          ) : (
            children
          )
        }
      </Resource>
    </Suspense>
  );
}
