import cx from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { UserProfile } from 'types/user';
import Avatar from 'ui/elements/avatars/Avatar';
import { userRole } from 'ui/domain/Users/userUtils';
import styles from './styles.scss';

interface Props {
  user: UserProfile;
  className?: string;
  href?: string;
  actions?: Children;
  onClick?: () => void;
  textOverflow?: 'wrap' | 'hide';
}

function InnerElement({ href, children }: { href?: string; children: Children }) {
  return href ? <Link to={href}>{children}</Link> : children;
}

export default function UserElement(props: Props) {
  return (
    <div className={cx('u-flex-space-between', props.className)}>
      <InnerElement href={props.href}>
        <button
          onClick={props.actions ? props.onClick : undefined}
          className={cx('u-flex u-flex-align-center u-flex-grow', styles.userElement)}
        >
          <Avatar userName={props.user.name} imageUrl={props.user.imageUrl} className="u-content-spacing-right" />
          <div className={cx('u-flex u-flex--column u-align-left', styles.userElement)}>
            <span className={cx(styles.userName, { [styles.wrapText]: props.textOverflow === 'hide' })}>
              {props.user.name}
            </span>
            <span className={cx(styles.userRole, { [styles.wrapText]: props.textOverflow === 'hide' })}>
              {userRole(props.user)}
            </span>
          </div>
        </button>
      </InnerElement>
      {props.actions}
    </div>
  );
}
