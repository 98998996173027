import useResourceLegacy from 'util/resource/useResourceLegacy';
import React from 'react';
import { DetailedUserProfile } from 'types/user';
import { BackdropColor } from 'ui/theme/themeBluePlanet';
import CenteredModalDialog from 'ui/views/dialogs/CenteredModalDialog';
import LoadableResources from 'util/resource/Resources';
import UserProfile from '.';
import { usersApi } from 'apis/CompanyAPI/users/usersApi';
import { BackofficeUserDetails } from 'pages/Backoffice/shared/BackofficeUserDetails';
import { useSelfUserProfile } from 'apis/CompanyAPI/users/useSelfUserProfile';

interface UserProfilePreviewProps {
  userProfile: DetailedUserProfile;
  onClose: () => void;
  backdropColor?: BackdropColor;
}

interface UserProfileDialogProps {
  cwUserId: UUID;
  onClose: () => void;
  backdropColor?: BackdropColor;
}

export function UserProfilePreview(props: UserProfilePreviewProps) {
  const { userProfile, onClose } = props;
  return (
    <CenteredModalDialog open={true} onClose={onClose} backdropColor={props.backdropColor}>
      <div className="u-content-spacing-top" style={{ maxWidth: '65rem', marginLeft: 'auto', marginRight: 'auto' }}>
        <UserProfile user={userProfile} showEdit={false} />
      </div>
    </CenteredModalDialog>
  );
}

export default function UserProfileDialog(props: UserProfileDialogProps) {
  const { cwUserId, onClose } = props;
  const [user] = useResourceLegacy(() => usersApi.userProfiles.getDetailed(cwUserId), [cwUserId]);
  const { resource: userProfileResource } = useSelfUserProfile();

  return (
    <CenteredModalDialog open={true} onClose={onClose} backdropColor={props.backdropColor}>
      <LoadableResources resources={[user, userProfileResource]}>
        {([userProfile, me]) => (
          <div className="u-content-spacing-top" style={{ maxWidth: '65rem', marginLeft: 'auto', marginRight: 'auto' }}>
            <UserProfile user={userProfile} showEdit={userProfile.cwUserId === me.cwUserId} />
            <BackofficeUserDetails userId={userProfile.id} />
          </div>
        )}
      </LoadableResources>
    </CenteredModalDialog>
  );
}
