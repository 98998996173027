import { MobileMargin } from 'ui/views/layouts/BaseLayout';
import Dialog, { Content, DialogActions, Title } from 'ui/views/dialogs/Dialog';
import Radio from 'ui/elements/form/choice/Radio';
import Button from 'ui/elements/buttons/Button';
import React, { useState } from 'react';
import Checkbox from 'ui/elements/form/choice/Checkbox';

interface SelectDialogProps {
  selectionType: 'single-select' | 'multi-select';
  selectionOptions: {
    name: string;
    selected: boolean;
  }[];
  title: string;
  onClose: () => void;
  isLoading: boolean;
  onSubmit: (selectedOptions: string[]) => void;
}

export default function SelectDialog(props: SelectDialogProps) {
  const isMultiSelect =
    props.selectionType === 'multi-select' || props.selectionOptions.filter(x => x.selected).length > 1;

  const [selectedOptions, setSelectedOptions] = useState<
    {
      name: string;
      selected: boolean;
    }[]
  >(props.selectionOptions);

  const multiSelectChangeOption = (name: string, isSelected: boolean) => {
    setSelectedOptions(
      selectedOptions.map(option => {
        if (option.name === name) {
          return {
            selected: isSelected,
            name: option.name,
          };
        }
        return option;
      }),
    );
  };

  const singleSelectChangeOption = (name: string) => {
    setSelectedOptions(
      selectedOptions.map(option => ({
        selected: option.name === name,
        name: option.name,
      })),
    );
  };

  return (
    <Dialog open={true} onClose={props.onClose} backdropColor="light" maxWidth="sm">
      <MobileMargin>
        <Title style={{ paddingBottom: 0 }} onClose={props.onClose}>{props.title}</Title>
        <Content style={{ paddingTop: 0, paddingBottom: 0 }}>
          <div className={'u-flex u-flex--column'}>
            {selectedOptions.map(option =>
              isMultiSelect ? (
                <Checkbox
                  key={option.name}
                  checked={option.selected}
                  onChange={() => multiSelectChangeOption(option.name, !option.selected)}
                  label={option.name}
                />
              ) : (
                <div
                  key={option.name}
                  className={'u-flex u-flex--gap-half u-flex-align-center'}
                  onClick={() => singleSelectChangeOption(option.name)}
                >
                  <Radio checked={option.selected} />
                  <span>{option.name}</span>
                </div>
              ),
            )}
          </div>
        </Content>
        <DialogActions>
          <Button
            kind="primary"
            isLoading={props.isLoading}
            onClick={() => props.onSubmit(selectedOptions.filter(x => x.selected).map(x => x.name))}
          >
            Assign
          </Button>
          <Button kind="tertiary" onClick={props.onClose}>
            Cancel
          </Button>
        </DialogActions>
      </MobileMargin>
    </Dialog>
  );
}
