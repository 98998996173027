import styled from '@emotion/styled';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { useSelfUserProfile } from 'apis/CompanyAPI/users/useSelfUserProfile';
import OrganizationAPI from 'apis/OrganizationAPI';
import { useUpdateOrganization } from 'apis/OrganizationAPI/organizations/useUpdateOrganization';
import UserConversationButton from 'domain/conversations/UserConversationButton';
import Banner from 'domain/shared/Banner';
import LocationInput from 'domain/shared/Location/LocationInput';
import UserProfileDialogRoute, { userDialogUrl } from 'domain/users/UserProfile/UserProfileDialogRoute';
import { useFormik } from 'formik';
import EditOrganizationImagesDialog from 'pages/Organization/shared/EditOrganizationImagedDialog';
import EditableIndustries from 'pages/Organization/shared/EditableIndustries';
import EditableOrganizationTypes from 'pages/Organization/shared/EditableOrganizationType';
import EditableServices from 'pages/Organization/shared/EditableServices';
import NotFound from 'pages/common/error/NotFoundPage';
import React, { useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Organization } from 'types/organization';
import { UserProfile } from 'types/user';
import IndustryChip from 'ui/domain/Chips/IndustryChip';
import EventPreview from 'ui/domain/Event';
import Logo from 'ui/domain/Logo';
import UserElement from 'ui/domain/Users/UserElement';
import ChipList from 'ui/elements/Chip/ChipList';
import SectionHeading from 'ui/elements/SectionHeading';
import AnchorButton from 'ui/elements/buttons/AnchorButton';
import CtaButton from 'ui/elements/buttons/CtaButton';
import styles from 'ui/elements/dictionary/styles.scss';
import InlineEditableField from 'ui/elements/form/InlineEditableField';
import TextField from 'ui/elements/form/TextField';
import ChatBubblesIcon from 'ui/elements/icons/ChatBubblesIcon';
import EditIcon from 'ui/elements/icons/EditIcon';
import IconButton from 'ui/elements/icons/IconButton';
import { contentSpacing, sectionSpacing } from 'ui/theme/themeConstants';
import Card from 'ui/views/cards/Card';
import { CardGrid } from 'ui/views/cards/CardGrid';
import { DialogBanner } from 'ui/views/dialogs/CenteredModalDialog';
import BaseLayout, { MobileMargin } from 'ui/views/layouts/BaseLayout';
import HeadTags from 'ui/views/layouts/BaseLayout/PageTitle';
import TruncatedList from 'util/components/TruncatedList';
import i18n from 'util/i18n';
import { formatShortLocation } from 'util/locationUtils';
import { getOrUndefined } from 'util/resource';
import Resource from 'util/resource/Resource';
import useResource from 'util/resource/useResource';
import { scrollToTop } from 'util/scroll';
import { addProtocolIfMissing } from 'util/stringUtils';
import { validWebsiteRegex } from 'util/validation';
import * as Yup from 'yup';
import ServiceListing from './ServiceListing';

interface OrganizationPageProps {
  organization: Organization;
}

export default function OrganizationPage(props: OrganizationPageProps) {
  return (
    <BaseLayout title="Organization">
      <MobileMargin>
        <EditableOrganizationProfile organization={props.organization} isModal={false} />
      </MobileMargin>
    </BaseLayout>
  );
}

interface PageProps {
  slug: string;
  isModal: boolean;
}

export function OrganizationPageContents(props: PageProps) {
  const { resource: organizationResource } = useResource<Organization>(OrganizationAPI.getBySlug(props.slug));

  return (
    <Resource resource={organizationResource} renderError={() => <NotFound />}>
      {organization => {
        return (
          <MobileMargin>
            <EditableOrganizationProfile organization={organization} isModal={props.isModal} />
          </MobileMargin>
        );
      }}
    </Resource>
  );
}

const StyledHorizontalDictionary = styled.dl(
  (props: { columnCount: number; columnWidth?: number; gridGap?: 'section' | 'content' }) => `
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(
      min(
        100%,
        max(
          ${props.columnWidth ?? 100}px${props.columnCount ? ', ' + 100 / (props.columnCount + 1) + '%' : ''}
        )
      ),
      1fr
    )
  );
  grid-gap: ${props.gridGap === 'section' ? sectionSpacing : contentSpacing};
`,
);

export function EditableOrganizationProfile({
  organization,
  isModal,
}: {
  organization: Organization;
  isModal: boolean;
}) {
  const [updateOrganization] = useUpdateOrganization(organization);

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      website: organization.website,
    },
    validationSchema: Yup.object().shape({
      website: Yup.string()
        .notRequired()
        .matches(validWebsiteRegex, 'Website should be a valid url, e.g. https://example.com')
        .nullable(),
    }),
    onSubmit: values =>
      updateOrganization({
        website: values.website,
      }),
  });

  const canEdit = organization.isAdmin ?? false;

  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const contactPerson = organization.contacts.length > 0 ? organization.contacts[0] : null;

  const sectionContentRef = useRef<HTMLDivElement>(null);

  const pickerMaxWidth = Math.min(
    sectionContentRef.current ? sectionContentRef.current.clientWidth : 400,
    isMobile ? 300 : 500,
  );

  const pickerMinWidth = isMobile ? pickerMaxWidth / 2 : pickerMaxWidth / 3;

  const [isEditImagesDialogOpen, setIsEditImagesDialogOpen] = useState(false);

  const openEditImagesDialog = () => {
    setIsEditImagesDialogOpen(true);
  };

  const closeEditImagesDialog = () => {
    setIsEditImagesDialogOpen(false);
    scrollToTop();
  };

  return (
    <>
      <HeadTags title={organization.name} />
      {canEdit && !organization.bannerUrl ? (
        <CtaButton
          style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
          height={245}
          onClick={openEditImagesDialog}
        >
          Add a banner image
        </CtaButton>
      ) : (
        <>
          {isModal && <DialogBanner imageURL={organization.bannerUrl} />}
          {!isModal && <Banner imageURL={organization.bannerUrl} />}
        </>
      )}
      <div>
        <div className="u-flex u-flex-align-center u-content-spacing-y">
          {canEdit && !organization.logoUrl ? (
            <CtaButton
              className={'u-content-spacing-left'}
              shape="circle"
              height={60}
              onClick={openEditImagesDialog}
            >
              Add logo
            </CtaButton>
          ) : (
            <Logo size={60} url={organization.logoUrl} type="organization" />
          )}
          {canEdit ? (
            <span className="u-content-spacing-left">
              <InlineEditableField
                inputWrapperStyle={{ minWidth: pickerMinWidth, maxWidth: pickerMaxWidth }}
                onConfirm={value =>
                  updateOrganization({
                    name: value,
                  })
                }
                renderInputComponent={(onChange, value) => (
                  <TextField
                    autoFocus
                    placeholder="Name"
                    type="text"
                    value={value}
                    onChange={e => {
                      onChange(e.target.value);
                    }}
                  />
                )}
                valueComponent={<h2 className="text-h3">{organization.name}</h2>}
                defaultValue={organization.name}
                label={'Add name'}
              />
            </span>
          ) : (
            <span className="u-content-spacing-left">
              <h2 className="text-h3">{organization.name}</h2>
            </span>
          )}
        </div>
        <div className="u-flex u-flex-space-between u-content-spacing-bottom u-flex--wrap">
          <EditableIndustries
            canEdit={canEdit}
            style={{ minWidth: pickerMinWidth, maxWidth: pickerMaxWidth }}
            onConfirm={value =>
              updateOrganization({
                industries: value.map(i => i.id),
              })
            }
            initialIndustriesNames={organization.industries}
            valueComponent={
              <ChipList>
                <TruncatedList
                  values={organization.industries.map(i => ({ name: i }))}
                  limit={3}
                  renderItem={label => <IndustryChip label={label.name} />}
                  renderExpandButton={(label, onClick) => <IndustryChip onClick={onClick} label={label.name} />}
                />
              </ChipList>
            }
          />
          {canEdit && (
            <IconButton onClick={openEditImagesDialog}>
              <EditIcon />
            </IconButton>
          )}
        </div>
      </div>
      <Grid container>
        <Grid item xs={12} md={8}>
          <div ref={sectionContentRef} className={isMdUp ? 'u-section-spacing-right' : 'u-section-spacing-bottom'}>
            <Section>
              <Card padding="double">
                {canEdit ? (
                  <InlineEditableField
                    label="Add description"
                    buttonStyle={{ height: '100px' }}
                    defaultValue={organization.description ?? ''}
                    onConfirm={value =>
                      updateOrganization({
                        description: value,
                      })
                    }
                    renderInputComponent={(onChange, value) => (
                      <TextField
                        autoFocus
                        placeholder="Description"
                        type="text"
                        multiline
                        minRows={4}
                        maxRows={20}
                        value={value}
                        onChange={e => {
                          onChange(e.target.value);
                        }}
                      />
                    )}
                    valueComponent={<p className="text-body">{organization.description}</p>}
                  />
                ) : (
                  <p className="text-body">{organization.description}</p>
                )}

                <Section
                  condition={
                    canEdit ||
                    organization.types.length > 0 ||
                    organization.location !== undefined ||
                    organization.established !== undefined
                  }
                >
                  <StyledHorizontalDictionary columnCount={3} className={'u-section-spacing-top'}>
                    <EditableOrganizationTypes
                      canEdit={canEdit}
                      style={{ minWidth: isMobile ? pickerMaxWidth : pickerMinWidth, maxWidth: pickerMaxWidth }}
                      onConfirm={value =>
                        updateOrganization({
                          types: value.map(t => t.id),
                        })
                      }
                      initialTypesNames={organization.types}
                      valueComponent={
                        organization.types.length > 0 && (
                          <div>
                            <dt className="text-subheading-2">Type</dt>
                            <dd className={styles.horizontalValue}>{organization.types.join(', ')}</dd>
                          </div>
                        )
                      }
                    />

                    {canEdit ? (
                      <InlineEditableField
                        label="Add location"
                        defaultValue={organization.location ?? ''}
                        onConfirm={value =>
                          updateOrganization({
                            location: value,
                          })
                        }
                        renderInputComponent={onChange => (
                          <LocationInput
                            style={{ minWidth: pickerMinWidth, maxWidth: pickerMaxWidth }}
                            name="location"
                            searchArea='places'
                            placeholder="Search for location"
                            autoComplete="address-level2"
                            autoFocus
                            onChange={v => onChange(v ? formatShortLocation(v) : '')}
                          />
                        )}
                        valueComponent={
                          organization.location && (
                            <>
                              <dt className="text-subheading-2">Location</dt>
                              <dd className={styles.horizontalValue}>{organization.location}</dd>
                            </>
                          )
                        }
                      />
                    ) : (
                      organization.location && (
                        <div className="text-font-display u-flex-inline-align-center">
                          <dt className="text-subheading-2">Location</dt>
                          <dd className={styles.horizontalValue}>{organization.location}</dd>
                        </div>
                      )
                    )}

                    {canEdit ? (
                      <InlineEditableField
                        label="Add established"
                        defaultValue={organization.established ?? ''}
                        onConfirm={value =>
                          updateOrganization({
                            established: value,
                          })
                        }
                        renderInputComponent={(onChange, value) => (
                          <TextField
                            autoFocus
                            placeholder='e.g. "2015"'
                            type="number"
                            value={value}
                            onChange={e => {
                              onChange(e.target.value);
                            }}
                          />
                        )}
                        valueComponent={
                          <>
                            <dt className="text-subheading-2">Established</dt>
                            <dd className={styles.horizontalValue}>{organization.established}</dd>
                          </>
                        }
                      />
                    ) : (
                      organization.established && (
                        <div className="text-font-display u-flex-inline-align-center">
                          <dt className="text-subheading-2">Established</dt>
                          <dd className={styles.horizontalValue}>{organization.established}</dd>
                        </div>
                      )
                    )}
                  </StyledHorizontalDictionary>
                </Section>

                {canEdit ? (
                  <div style={{ display: 'inline-block' }}>
                    <InlineEditableField
                      label="Add website"
                      error={formik.errors.website}
                      defaultValue={organization.website ?? ''}
                      onConfirm={async value => {
                        await formik.setFieldValue('website', value);
                        return formik.validateField('website').then(() => {
                          formik.submitForm();
                        });
                      }}
                      renderInputComponent={(onChange, value) => (
                        <TextField
                          autoFocus
                          value={value}
                          style={{ minWidth: pickerMinWidth, maxWidth: pickerMaxWidth }}
                          name="website"
                          error={!!formik.errors.website}
                          placeholder={i18n('en').placeholders.website}
                          onBlur={formik.handleBlur}
                          onChange={e => {
                            formik.setFieldValue('website', e.target.value, false);
                            onChange(e.target.value);
                          }}
                        />
                      )}
                      valueComponent={
                        organization.website && (
                          <div>
                            <AnchorButton kind="primary" target="_blank" rel="noopener">
                              Visit website
                            </AnchorButton>
                          </div>
                        )
                      }
                    />
                  </div>
                ) : (
                  organization.website && (
                    <div>
                      <AnchorButton
                        href={addProtocolIfMissing(organization.website)}
                        kind="primary"
                        target="_blank"
                        rel="noopener"
                      >
                        Visit website
                      </AnchorButton>
                    </div>
                  )
                )}
              </Card>
            </Section>
            <Section condition={organization.services.length > 0 || canEdit}>
              <SectionHeading heading="Our services" />
              <Card padding="double">
                <EditableServices
                  canEdit={canEdit}
                  style={{ minWidth: pickerMinWidth, maxWidth: pickerMaxWidth }}
                  onConfirm={value =>
                    updateOrganization({
                      services: value.map(s => s.id),
                    })
                  }
                  initialServices={organization.services}
                  valueComponent={<ServiceListing services={organization.services} contactPerson={contactPerson} />}
                />
              </Card>
            </Section>
            <Section condition={organization.events.length > 0}>
              <SectionHeading heading="Events" />
              <CardGrid
                mobile={{ columnCount: 1 }}
                tablet={{ columnCount: 2 }}
                desktop={{ columnCount: 2 }}
                xl={{ columnCount: isModal ? 2 : 3 }}
                xxl={{ columnCount: isModal ? 2 : 4 }}
              >
                {organization.events.map(event => (
                  <EventPreview key={event.id} event={event} trackingKey="organization-event-preview" />
                ))}
              </CardGrid>
            </Section>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <Section condition={organization.contacts.length > 0}>
            <SectionHeading heading="Contact" />
            <UserCardListing users={organization.contacts} showChatIcon={true} />
          </Section>
          <Section condition={organization.team.length > 0}>
            <SectionHeading heading="Team" />
            <UserCardListing users={organization.team} showChatIcon={false} />
          </Section>
        </Grid>
      </Grid>

      <UserProfileDialogRoute />
      {isEditImagesDialogOpen && (
        <EditOrganizationImagesDialog
          onClose={closeEditImagesDialog}
          logoUrl={organization.logoUrl}
          bannerUrl={organization.bannerUrl}
          organizationId={organization.id}
          onLogoChanged={url =>
            updateOrganization({
              logoUrl: url,
            })
          }
          onBannerImageChanged={url =>
            updateOrganization({
              bannerUrl: url,
            })
          }
        />
      )}
    </>
  );
}

function UserCardListing({ users, showChatIcon }: { users: UserProfile[]; showChatIcon: boolean }) {
  const { resource: userResource } = useSelfUserProfile();
  const loggedInUser = getOrUndefined(userResource);
  const match = useRouteMatch();

  return (
    <>
      {users.map(user => (
        <Card key={user.id} className="u-half-spacing-bottom">
          <UserElement
            href={userDialogUrl(match.url, user.cwUserId)}
            user={user}
            actions={
              showChatIcon &&
              user.cwUserId !== loggedInUser?.cwUserId && (
                <UserConversationButton className="text-medium" conversationWith={[user.cwUserId]}>
                  <ChatBubblesIcon fontSize="small"></ChatBubblesIcon>
                </UserConversationButton>
              )
            }
          />
        </Card>
      ))}
    </>
  );
}

const Div = styled.div`
  &:not(:last-of-type) {
    margin-bottom: ${sectionSpacing};
  }
`;

function Section({ condition, children }: { condition?: boolean | undefined; children: React.ReactNode }) {
  return <>{(condition || condition === undefined) && <Div>{children}</Div>}</>;
}
