import { MarketPotential } from 'apis/CompanyAPI/companies/useMarketPotential';
import React from 'react';
import Button from 'ui/elements/buttons/Button';
import Dialog, { Content, DialogActions, Title } from 'ui/views/dialogs/Dialog';
import { useMarketPotentialForm, useSaveMarketPotential } from 'domain/companies/MarketPotential/utils';
import { MarketPotentialField, MarketPotentialFields } from 'domain/companies/MarketPotential/MarketPotentialFields';
import { CompanyProfile } from 'types/company';

interface Props {
  company: CompanyProfile;
  marketPotential?: MarketPotential;
  isOpen: boolean;
  onClose: () => void;
  onComplete: () => void;
  autoFocusField?: MarketPotentialField;
}

export default function MarketPotentialDialog({
  company,
  marketPotential,
  onComplete,
  onClose,
  isOpen,
  autoFocusField,
}: Props) {
  const [onSaveMarketPotential, isSavingMarketPotential] = useSaveMarketPotential(company.id, {
    onSuccess: () => {
      onComplete();
      onClose();
    },
  });
  const formik = useMarketPotentialForm(onSaveMarketPotential, marketPotential);

  return (
    <Dialog onClose={onClose} open={isOpen} maxWidth="md">
      <Title onClose={onClose}>Edit market potential</Title>
      <form onSubmit={formik.handleSubmit}>
        <Content>
          <MarketPotentialFields company={company} autoFocusField={autoFocusField} {...formik} />
        </Content>
        <DialogActions>
          <Button kind="primary" onClick={() => formik.handleSubmit()} isLoading={isSavingMarketPotential}>
            Update
          </Button>
          <Button kind="tertiary" onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
