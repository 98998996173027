import React, { useEffect, useState, useRef } from 'react';
import styles from './styles.scss';
import Card from 'ui/views/cards/Card';
import LinkButton from 'ui/elements/buttons/LinkButton';
import MaxWidth from 'ui/views/containers/MaxWidth';
import { useLocation } from 'react-router';
import { notEmpty } from 'util/arrayUtils';
import { useMediaQuery, useTheme } from '@mui/material';
import { BODY_ID } from 'ui/views/layouts/BaseLayout/ScrollableBody';

interface Section<T extends string | undefined> {
  key: T;
  name: string;
  element: JSX.Element;
}

interface Props<T extends string | undefined> {
  sections: Section<T>[];
  onMenuItemClick?: (key: T) => void;
  additionalMenuItems?: JSX.Element;
  children?: (sections: JSX.Element[]) => JSX.Element;
}

export default function ScrollNavigation<T extends string | undefined>(props: Props<T>) {
  const { sections, additionalMenuItems, onMenuItemClick, children } = props;
  const location = useLocation();
  const [activeSection, setActiveSection] = useState<T | undefined>(sections[0]?.key);
  const refs = useRef<(HTMLDivElement | null)[]>([]);
  const body = document.getElementById(BODY_ID);

  useEffect(() => {
    body?.addEventListener('scroll', handleScroll);
    return () => body?.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const sectionFromUrl = new URLSearchParams(location.search).get('section')?.toLowerCase() ?? undefined;
    if (sections.map(s => s.key).includes(sectionFromUrl as T)) {
      // There's a race condition here if the container page scrolls to top upon navigation
      setTimeout(() => {
        handleSelectSection(sectionFromUrl as T);
      }, 5);
    }
  }, []);

  function handleScroll() {
    const r = refs.current.filter(notEmpty);
    if (r.length > 0) {
      const nearestSection = r.reduce((prev, curr) => {
        return Math.abs(prev.getBoundingClientRect().top) < Math.abs(curr.getBoundingClientRect().top) ? prev : curr;
      });
      setActiveSection(nearestSection.id as T);
    }
  }

  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  function handleSelectSection(section: T) {
    if (!section) {
      return;
    }

    setActiveSection(section);
    onMenuItemClick && onMenuItemClick(section);
    const el = document.getElementById(section);

    // The 20px offset from the top of the element, makes the section we scroll to
    // align with the navigation menu box at the left (visible on desktop)
    // The 60px offset is the mobile top navigation bar
    const deviceOffsetTop = isMdUp ? 20 : 60;

    if (el) {
      body?.scrollTo({ top: el.offsetTop - deviceOffsetTop ?? 0, behavior: 'smooth' });
    }
  }

  const RenderedSections = sections.map((section, i) => (
    <div ref={element => (refs.current[i] = element)} id={section.key} key={section.key}>
      {section.element}
    </div>
  ));

  return (
    <div className="u-flex u-flex--align-items-start">
      <Card className={styles.nav}>
        {sections.map(section => (
          <LinkButton
            key={section.key}
            size="small"
            className={`u-ellipsis ${activeSection === section.key ? styles.activeTab : undefined}`}
            onClick={() => handleSelectSection(section.key)}
          >
            {section.name}
          </LinkButton>
        ))}
        <div className="u-flex u-flex--column u-section-spacing-top">{additionalMenuItems}</div>
      </Card>
      <MaxWidth width="xl">
        {children ? children(RenderedSections) : RenderedSections}
        {/* Make sure there is always space to scroll to every section so that the navigation works */}
        <div style={{ height: '90vh' }} />
      </MaxWidth>
    </div>
  );
}
