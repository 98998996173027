import React from 'react';
import {
  roleArticle as companyRoleArticle,
  roleDisplayName as companyRoleDisplayName,
} from 'domain/companies/roleUtils';
import SmallAccordion from 'ui/modules/Accordion/SmallAccordion';
import { ICompany, Role } from 'types/company';
import { unCapitalize } from 'util/stringUtils';
import { Link } from 'react-router-dom';
import { communityUrls, companyUrls } from 'urls';
import { DetailedUserProfile } from 'types/user';
import { notEmpty } from 'util/arrayUtils';
import groupBy from 'ramda/src/groupBy';
import sort from 'ramda/src/sort';

interface Props {
  user: DetailedUserProfile;
  linkClassName?: string;
}

function joinToElements(list: { company: ICompany }[], transform: (company: ICompany) => JSX.Element | string) {
  return list.map((item, index) => (
    <span key={`item-${item.company.id}`}>
      {transform(item.company)}
      {index < list.length - 2 && ', '}
      {index == list.length - 2 && ' and '}
    </span>
  ));
}

const companyPlural = (num: number) => (num === 1 ? 'company' : 'companies');

function roleSortValue(role: Role) {
  const roles = [
    'prospective_investor',
    'mentor',
    'advisor',
    'investor',
    'employee',
    'board',
    'company_admin_in_community',
    'company_master',
  ];
  return roles.indexOf(role);
}

function sortRoles(role: Role, other: Role) {
  return roleSortValue(other) - roleSortValue(role);
}

// Displays all companies user manages that is also part
// of a community you are a part of
function companyAdminInCommunityText(user: DetailedUserProfile, linkClassName?: string) {
  const allCommunitiesAsCompanyAdmin = joinToElements(
    user.companiesInCommon.filter(item => item.role === 'company_admin_in_community'),
    item => (
      <Link className={linkClassName} to={communityUrls.overview(item.slug)}>
        {item.name}
      </Link>
    ),
  );

  const companiesUserManagesInCommunities = joinToElements(
    user.companiesInCommon.filter(item => item.isCompanyInCommunity),
    item => (
      <a className={linkClassName} href={companyUrls.overview(item.slug, 'profile')}>
        {item.name}
      </a>
    ),
  );

  // User isn't company admin in any communities that I am a part of
  if (allCommunitiesAsCompanyAdmin.length === 0 && companiesUserManagesInCommunities.length === 0) return null;

  // User is company admin in any communities that I am a part of, and the companies are visible in the community
  if (companiesUserManagesInCommunities.length > 0) {
    return (
      <>
        <span>{user.name}</span> is managing the {companyPlural(companiesUserManagesInCommunities.length)}{' '}
        <span>{companiesUserManagesInCommunities}</span> and is a part of{' '}
        {allCommunitiesAsCompanyAdmin.length > 0 ? allCommunitiesAsCompanyAdmin : ' a community you are a part of'}
      </>
    );
  }

  // User is a company admin in any communities that I am a part of, but the companies aren't visible
  return (
    <>
      <span>{user.name}</span> is a part of{' '}
      {allCommunitiesAsCompanyAdmin.length > 0 ? allCommunitiesAsCompanyAdmin : ' a community you are a part of'}
    </>
  );
}

// displays all roles user has in communities in common with you
// except the 'company admin in a community' role
function communityTexts(user: DetailedUserProfile, linkClassName?: string) {
  const companiesInCommon = user.companiesInCommon;
  const allCommunityRolesExceptAsCompanyAdmin = groupBy(
    item => item.role,
    companiesInCommon.filter(item => item.role !== 'company_admin_in_community' && item.isCommunity),
  );

  return Object.keys(allCommunityRolesExceptAsCompanyAdmin).map((role: Role) => {
    const companyNames = joinToElements(allCommunityRolesExceptAsCompanyAdmin[role], item => (
      <Link className={linkClassName} to={communityUrls.overview(item.slug)}>
        {item.name}
      </Link>
    ));
    const displayText = `is a part of `;
    return (
      <div key={`insight-${role}`}>
        <span>{user.name}</span> {displayText} <span>{companyNames}</span>
      </div>
    );
  });
}

// displays all roles user has in companies in common with you
function companyTexts(user: DetailedUserProfile, linkClassName?: string) {
  const allCompanies = groupBy(
    item => item.role,
    user.companiesInCommon.filter(item => !item.isCommunity && !item.isCompanyInCommunity),
  );

  const sortedRoles = sort(sortRoles, Object.keys(allCompanies));

  return sortedRoles.map((role: Role) => {
    const companyNames = joinToElements(allCompanies[role], item => (
      <a className={linkClassName} href={companyUrls.overview(item.slug, 'profile')}>
        {item.name}
      </a>
    ));
    const displayText =
      role === 'company_master'
        ? `is managing the ${companyPlural(companyNames.length)}`
        : `is ${companyRoleArticle(role)} ${unCapitalize(companyRoleDisplayName(role))} in`;
    return (
      <div key={`insight-${role}`}>
        <span>{user.name}</span> {displayText} <span>{companyNames}</span>
      </div>
    );
  });
}

export default function InsightsContent(props: Props) {
  const allItems = [
    companyAdminInCommunityText(props.user, props.linkClassName),
    ...companyTexts(props.user, props.linkClassName),
    ...communityTexts(props.user, props.linkClassName),
  ].filter(notEmpty);

  return (
    <div>
      {allItems.length === 1 && <div>{allItems[0]}</div>}

      {allItems.length > 1 && (
        <SmallAccordion
          title={allItems[0]}
          viewMoreText={`Show ${allItems.length - 1} more`}
          viewLessText="Show less"
          align="right"
        >
          {allItems.slice(1).map((common, i) => (
            <div key={`shared-connection-${i}`}>{common}</div>
          ))}
        </SmallAccordion>
      )}
    </div>
  );
}
