import { communityAPIUrls } from 'apis/CompanyAPI/communities/communitiesApi';
import { CommunityApplicationQuestion } from 'apis/CompanyAPI/communities/types/communityQuestions';
import useSWR from 'hooks/useSWR';
import { useSWRResource } from 'util/resource/useSWRResource';

export const companyApplicationKey = (communitySlug: string, companyId: number) =>
  communityAPIUrls.applications.listForCompany(communitySlug, companyId);

export const useCompanyApplication = (communitySlug: string | undefined, companyId: number | undefined) => {
  const swrResult = useSWR<CommunityApplicationQuestion[]>(
    communitySlug && companyId ? companyApplicationKey(communitySlug, companyId) : undefined,
  );

  return useSWRResource(swrResult);
};
