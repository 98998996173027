import { Role } from 'types/company';
import { CommunityType } from 'types/company/community';

export const isInnovasjonNorge = (communityType?: CommunityType) =>
  communityType === 'mentor_network' || communityType === 'mentor_master_network';

export const roleDisplayNamePlural = (role: Role) => {
  switch (role) {
    case 'company_master':
      return 'Company admins';
    case 'board':
      return 'Board members';
    case 'investor':
      return 'Investors';
    case 'employee':
      return 'Employees';
    case 'prospective_investor':
      return 'Followers';
    case 'advisor':
      return 'Advisors';
    case 'mentor':
      return 'Mentors';
    case 'company_admin_in_community':
      return 'Companies';
  }
};
