import cx from 'classnames';
import React from 'react';
import { staticFileLocations } from 'urls';
import { resize } from 'util/cloudinary';
import { LOGO_MIN_SIZE } from 'util/constants';
import styles from './styles.scss';

interface Props {
  url?: string;
  className?: string;
  type: ContextType;
  size: number;
  shape?: 'round' | 'square';
  children?: React.ReactNode;
}

type ContextType = 'company' | 'community' | 'organization';

export default function Logo({ url, type, size, children, className, shape }: Props) {
  const logourl = url || getDefaultProfileImage(type);

  const sourceSize = size < LOGO_MIN_SIZE ? LOGO_MIN_SIZE : size;

  const logoSrc = resize(logourl, { width: sourceSize, height: sourceSize, backgroundColor: 'white' });
  return (
    <div className={cx(styles.container, className)}>
      <div
        className={cx(styles.logo, { [styles.square]: shape == 'square' })}
        style={{
          width: size,
          height: size,
          fontSize: size,
        }}
      >
        <img src={logoSrc} alt={type + ' logo'} width={size} height={size} />
      </div>
      {children}
    </div>
  );
}

function getDefaultProfileImage(type: ContextType) {
  switch (type) {
    case 'community':
      return staticFileLocations.defaultCommunityProfileImage;
    case 'company':
      return staticFileLocations.defaultCompanyProfileImage;
    case 'organization':
      return staticFileLocations.defaultCommunityProfileImage;
  }
}
