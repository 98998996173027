import React from 'react';
import BulbIcon from 'ui/elements/icons/BulbIcon';
import { DetailedUserProfile } from 'types/user';
import Card from 'ui/views/cards/Card';
import InsightsContent from './InsightsContent';

interface Props {
  user: DetailedUserProfile;
}

export function ConversationInsights(props: Props) {
  const companiesInCommon = props.user.companiesInCommon;
  if (companiesInCommon.length === 0) {
    return null;
  }
  return (
    <div className="u-content-spacing-top text-small u-flex">
      <BulbIcon className="u-quarter-spacing-right text-medium" style={{ marginTop: '2px' }} color="blue" />
      <div style={{ maxWidth: '100%' }}>
        <InsightsContent user={props.user} linkClassName="text-link-small" />
      </div>
    </div>
  );
}

export default function Insights(props: Props) {
  const companiesInCommon = props.user.companiesInCommon;
  if (companiesInCommon.length === 0) {
    return null;
  }
  return (
    <Card color="indigo" className="u-content-spacing-bottom">
      <h3 className="u-flex u-flex-align-center u-half-spacing-bottom text-h3">
        <BulbIcon className="u-half-spacing-right" />
        Insights
      </h3>
      <InsightsContent user={props.user} linkClassName="text-link" />
    </Card>
  );
}
